import '@/assets/js/src/style/nprogress.scss'
import NProgress from 'nprogress'

let globalThis = (1, eval)('this')
if(globalThis.runningNP) throw new Error("double execution prevented")
globalThis.runningNP = true
if(!globalThis.bsNProgress) {

    NProgress.configure({
        trickleSpeed: 150,
        minimum: 0.2,
        showSpinner: false,
    })

    NProgress.start()

    globalThis.bsNProgress = NProgress
}

